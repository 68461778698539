import React, { useState } from "react";
import styled from "styled-components";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useMediaQuery } from "../hooks/useMediaQuery";
import CircleLoader from "./UI/CircleLoader";

const ItemWrapper = styled.div`
	flex: 1;
	position: relative;
	border-radius: 12px;
	border: ${(props) => (props.color ? `3px solid ${props.color}` : "none")};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
	cursor: ${(props) => (props.isMulti ? "pointer" : "initial")};
	padding: ${(props) => (props.isMulti ? "0 24px" : "0")};
`;

const TextWrapper = styled.div`
	font-family: "Montserrat", sans-serif;
	font-size: ${(props) =>
		props.primary
			? props.isSmall
				? "3.2vw"
				: "1.6vw"
			: props.isSmall
			? "1.9vw"
			: "1vw"};
	font-weight: ${(props) => (props.primary ? "bold" : "normal")};
	white-space: nowrap;
`;

const DataItem = ({ title, data, color, loaded, status }) => {
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleClick = () => {
		setCurrentIndex((prev) => (prev + 1) % data.length);
	};

	const getBorderColor = (param) => {
		return param === "restricted"
			? "red"
			: param === "allowed"
			? "#FFF85C"
			: "#0C9C23";
	};

	return (
		<ItemWrapper
			onClick={Array.isArray(data) ? handleClick : null}
			color={color ?? getBorderColor(status)}
			isMulti={Array.isArray(data)}
		>
			<TextWrapper
				style={{
					color: status === "restricted" ? "#FF0000" : "#000000",
				}}
				isSmall={isSmall || isPortrait}
			>
				{Array.isArray(title) ? title[currentIndex] : title}
			</TextWrapper>
			<TextWrapper
				style={{
					color: status === "restricted" ? "#FF0000" : "#000000",
				}}
				primary
				isSmall={isSmall || isPortrait}
			>
				{loaded ? (
					Array.isArray(data) ? (
						data[currentIndex] ?? "-"
					) : (
						data ?? "-"
					)
				) : (
					<CircleLoader />
				)}
			</TextWrapper>
			{Array.isArray(title) && (
				<ChangeCircleIcon
					sx={{
						position: "absolute",
						top: "2px",
						right: "2px",
						width: "20px",
						height: "20px",
						color: "rgb(170, 170, 170)",
					}}
				/>
			)}
		</ItemWrapper>
	);
};

export default DataItem;
