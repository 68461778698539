import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Panel from "./pages/Panel";
import Report from "./pages/NewReport";
import Settings from "./pages/Settings";
import Statistic from "./pages/Statistic";
import Login from "./pages/Login";
import { RequireAuth } from "./hoc/RequireAuth";
import {
	getNewBatchInfo,
	setNewBatchFlag,
	setUuid,
} from "./store/opPanelSlice";
import { AuthProvider } from "./hoc/AuthProvider";
import ColorList from "./components/color/ColorList";
import ConfigList from "./components/settings/ConfigList";
import LayoutList from "./components/settings/LayoutList";
import ColorItem from "./components/color/ColorItem";
import DrySide from "./components/rfid/DrySide";
import ColorParams from "./components/color/ColorParams";
import NotionPage from "./components/notion/NotionPage";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./store/userSlice";
import ProfilePage from "./components/profile/ProfilePage";
import { getPalletById } from "./store/palletSlice";
import { getChartByScheme } from "./store/clickedSlice";
import { switchLight, setDrySideUuid } from "./store/rfidSlice";
import AddLayout from "./components/layout/AddLayout";
import UpdateForm from "./components/layout/UpdateForm";
import { getEquipment, getSystem } from "./store/settingsSlice";
import DefectsInspectionPage from "./components/DefectsInspectionPage";
import { DefectInspectionTest } from "./components/DefectInspectionTest";
import { UserManagementPanel } from "./components/UserManagementPanel";
import { DeprecationStats } from "./pages/DeprecationStats";
import { useTranslation } from "react-i18next";

function App() {
	const { apiToken } = useSelector((state) => state.auth);
	const { users } = useSelector((state) => state.user);
	const { isHistoryOnly } = useSelector((state) => state.opPanel);
	const [systemStatusIndicators, setSystemStatusIndicators] = useState({
		qpbc: false,
		rfidws: false,
		rfidds: false,
	});
	const [blur, setBlur] = useState(true);
	const [waitingToReconnect, setWaitingToReconnect] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const ws = useRef(null);
	const { i18n } = useTranslation();

	const dispatch = useDispatch();

	useEffect(() => {
		if (waitingToReconnect || !apiToken) {
			return;
		}

		// Only set up the websocket once
		if (!ws.current) {
			const client = new WebSocket(
				`${window.location.protocol === "https:" ? "wss" : "ws"}://` +
					window.location.host +
					"/ws"
				// "ws://192.168.0.202:8000/ws"
			);
			ws.current = client;

			window.client = client;

			client.onerror = (e) => console.error(e);

			client.onopen = () => {
				setIsOpen(true);
				console.log("ws opened");
			};

			gettingData();

			client.onclose = () => {
				if (ws.current) {
					console.log("ws closed by server");
				} else {
					console.log("ws closed by app component unmount");
					return;
				}

				if (waitingToReconnect) {
					return;
				}

				setIsOpen(false);
				console.log("ws closed");
				setWaitingToReconnect(true);

				setTimeout(() => setWaitingToReconnect(null), 5000);
			};

			return () => {
				console.log("Cleanup");
				ws.current = null;

				client.close();
			};
		}
	}, [waitingToReconnect, apiToken]);

	useEffect(() => {
		if (apiToken) {
			dispatch(getEquipment());
			dispatch(getSystem());
			dispatch(getUser());
		}
	}, [apiToken]);

	useEffect(() => {
		if (localStorage.getItem("language")) {
			i18n.changeLanguage(localStorage.getItem("language"));
		} else {
			fetch("/language").then(async (response) => {
				const resultedLanguage = await response.text();
				i18n.changeLanguage(resultedLanguage);
				localStorage.setItem("language", resultedLanguage);
			});
		}
	}, []);

	useEffect(() => {
		dispatch(getNewBatchInfo());
		fetch("/version").then((response) => {
			response
				.json()
				.then((result) =>
					localStorage.setItem("version", result.version)
				);
		});
		const versionCheck = setInterval(() => {
			console.log("checked");
			fetch("/version").then((response) => {
				response.json().then((result) => {
					if (localStorage.getItem("version") !== result.version) {
						clearInterval(versionCheck);
						window.location.reload(true);
					}
				});
			});
		}, 10 * 60 * 1000);
		return () => {
			console.log("cleared");
			clearInterval(versionCheck);
		};
	}, []);

	const gettingData = useCallback(() => {
		if (!ws.current) return;

		ws.current.onmessage = (e) => {
			const msgs = e.data.split(" ");
			const [cmd, pallet_id] = msgs;
			console.log(`cmd - ${cmd}; pallet_id - ${pallet_id}`);
			switch (cmd) {
				case "new": {
					setBlur(true);
					dispatch(setUuid(pallet_id));
					dispatch(getPalletById(pallet_id));
					dispatch(getChartByScheme(pallet_id));
					break;
				}
				case "rfid": {
					dispatch(setDrySideUuid(pallet_id));
					dispatch(switchLight(true));
					break;
				}
				case "pallet-registered": {
					setSystemStatusIndicators((prev) => ({
						...prev,
						qpbc: true,
					}));
					setTimeout(() => {
						setSystemStatusIndicators((prev) => ({
							...prev,
							qpbc: false,
						}));
					}, 800);
					break;
				}
				case "rfid-ws-registered": {
					setSystemStatusIndicators((prev) => ({
						...prev,
						rfidws: true,
					}));
					setTimeout(() => {
						setSystemStatusIndicators((prev) => ({
							...prev,
							rfidws: false,
						}));
					}, 800);
					break;
				}
				case "rfid-ds-registered": {
					setSystemStatusIndicators((prev) => ({
						...prev,
						rfidds: true,
					}));
					setTimeout(() => {
						setSystemStatusIndicators((prev) => ({
							...prev,
							rfidds: false,
						}));
					}, 800);
					break;
				}
				case "new-event": {
					dispatch(getNewBatchInfo());
					break;
				}
				case "expired-event": {
					dispatch(setNewBatchFlag(false));
					break;
				}
				default:
					break;
			}
		};
	}, []);

	return (
		<div
			className="App"
			style={{
				backgroundColor: "#f6f6f6",
				width: "100%",
				maxHeight: "max-content",
				minHeight: "100vh",
			}}
		>
			<AuthProvider>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route
							index
							element={<Navigate to="/panel" replace />}
						/>
						<Route
							path="panel"
							element={
								<RequireAuth>
									<Panel
										blur={!isHistoryOnly ? blur : false}
										setBlur={setBlur}
									/>
								</RequireAuth>
							}
						/>
						<Route
							path="newReport"
							element={
								<RequireAuth>
									<Report />
								</RequireAuth>
							}
						/>
						<Route
							path="rfid"
							element={
								<RequireAuth>
									<DrySide />
								</RequireAuth>
							}
						/>
						<Route
							path="profile"
							element={
								<RequireAuth>
									<ProfilePage />
								</RequireAuth>
							}
						/>
						<Route
							path="notion"
							element={
								<RequireAuth>
									<NotionPage />
								</RequireAuth>
							}
						/>
						<Route
							path="statistic/*"
							element={
								<RequireAuth>
									<Statistic />
								</RequireAuth>
							}
						/>
						<Route
							path="deprecation"
							element={<DeprecationStats />}
						/>
						{
							<Route
								path="settings"
								element={
									<RequireAuth>
										<Settings />
									</RequireAuth>
								}
							>
								<Route
									path="color"
									element={
										<RequireAuth>
											<ColorList />
										</RequireAuth>
									}
								/>
								{["moderator", "admin"].includes(
									users?.role
								) && (
									<>
										<Route path="sense">
											<Route
												path=""
												element={
													<RequireAuth>
														<ColorParams />
													</RequireAuth>
												}
											/>
											<Route path="test">
												<Route
													path=""
													element={
														<RequireAuth>
															<DefectsInspectionPage />
														</RequireAuth>
													}
												/>
												<Route
													path=":index"
													element={
														<RequireAuth>
															<DefectInspectionTest />
														</RequireAuth>
													}
												/>
											</Route>
										</Route>
										<Route
											path="users"
											element={<UserManagementPanel />}
										/>
									</>
								)}

								{
									<Route
										path="config"
										element={
											<RequireAuth>
												<ConfigList />
											</RequireAuth>
										}
									/>
								}
								<Route
									path="form"
									element={
										<RequireAuth>
											<LayoutList />
										</RequireAuth>
									}
								/>
								<Route
									path="form/add"
									element={
										<RequireAuth>
											<AddLayout />
										</RequireAuth>
									}
								/>
							</Route>
						}
						{
							<Route
								path="settings/color/:id"
								element={
									<RequireAuth>
										<ColorItem />
									</RequireAuth>
								}
							/>
						}
						{
							<Route
								path="settings/form/:id"
								element={
									<RequireAuth>
										<UpdateForm />
									</RequireAuth>
								}
							/>
						}
					</Route>

					<Route path="login" element={<Login />} />
				</Routes>
			</AuthProvider>
		</div>
	);
}

export default App;
